import { useCallback, useMemo } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useStartOfDay } from 'hooks/useStartOfDay';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import { InferredEvent, InferredEventId, ReportWithInferredEvents } from './types';
import { inferredEventsQueryKeys } from './queryKeys';
import { getInferredEventsForOrganisation } from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useCurrentInferredEvents = <T = InferredEvent[]>(options: Options<InferredEvent[], T> = {}) => {
  const organisationId = useOrganisationId();
  const from = useStartOfDay();
  const until = from.endOf('day'); // TODO: replace this with "now" and update over time
  const queryKey = inferredEventsQueryKeys.allRange(organisationId, from, until);
  return useQuery<InferredEvent[], HttpResponseError, T>(
    {
      queryKey,
      queryFn: () => getInferredEventsForOrganisation(organisationId, from, until),
      ...options,
    }
  );
};

export const useCurrentInferredEventsForReports = (reports: Report[], options: Options<InferredEvent[], InferredEvent[]> = {}): ReportWithInferredEvents[] => {
  const featureAssets = useFeatureAssets('events.inferred_events');
  const inferredEvents = useCurrentInferredEvents({ ...options, enabled: options.enabled && featureAssets.some });
  const inferredEventsByReportId = useMemo(() => inferredEvents.data?.reduce<Record<number, InferredEventId[]>>((acc, key) => {
    if (!acc[key.reportId]?.push(key.eventId)) acc[key.reportId] = [key.eventId];
    return acc;
  }, []), [inferredEvents]);
  return useMemo(() => {
    if (!inferredEvents.data || inferredEvents.data.length === 0 || (!featureAssets.all && !featureAssets.some)) {
      return reports.map(r => ({
        ...r,
        inferredEvents: null,
      }));
    }

    return reports.map(r => ({
      ...r,
      inferredEvents: featureAssets.hasAssetId(r.assetId) ? inferredEventsByReportId?.[r.id] ?? null : null,
    }));
  }, [featureAssets.all, featureAssets.some, inferredEvents.data, inferredEventsByReportId, reports]);
};

export const useCurrentInferredEventsForReport = (report: Report, options: Options<InferredEvent[], InferredEvent[]> = {}): ReportWithInferredEvents => {
  const arr = useCurrentInferredEventsForReports([report], options);
  return useCallback(() => arr[0], [arr])();
};
