/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAssetGroupBy } from 'hooks/settings/useAssetGroupBy';
import { useAssetSortBy } from 'hooks/settings/useAssetSortBy';
import { selectItem } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import { useSearchParams } from 'react-router-dom';
import GroupedQueryResults from './groupedResults';
import useStyles from './results-styles';

interface OmniboxResultsProps {
  organisationId: string,
  selectedMapId: string,
  assignItemToMap: (mapId: string, asset: AssetBasic) => void,
  data: { assets: AssetBasic[] }
}

const OmniboxResults = ({
  organisationId,
  selectedMapId,
  assignItemToMap,
  data,
}: OmniboxResultsProps): JSX.Element => {
  const classes = useStyles();
  const selectedSortBy = useAssetSortBy();
  const selectedGroupBy = useAssetGroupBy();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const selectAsset = useCallback(asset => {
    dispatch(selectItem(asset));
    assignItemToMap(selectedMapId, { ...asset, organisationId });
    // TODO: performance - this use effect is selecting assets when switching maps, this is also happening in mapContainer-view
  }, [assignItemToMap, organisationId, dispatch, selectedMapId]);

  useEffect(() => {
    const assetId = searchParams.get('asset');
    if (assetId) {
      const asset = data.assets.find(a => a.id === Number(assetId));
      if (asset) {
        selectAsset(asset);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assets = useCallback(() => data.assets, [data])();

  return (
    <Box id="omniboxResults" className={classes.container}>
      <Box className={classes.listWrapper} data-test="assetContainer">
        {data.assets && (
          <GroupedQueryResults
            results={assets}
            groupBy={selectedGroupBy.assets}
            sortBy={selectedSortBy.assets}
            selectAsset={selectAsset}
          />
        )}
      </Box>
    </Box>
  );
};

export default OmniboxResults;
