import { DateTime } from 'luxon';
import { HttpResponseError, serenityFetch } from 'helpers/api';
import { ApiInferredEventsResponse, InferredEvent, InferredEventId } from './types';

export const getInferredEventsForOrganisation = async (organisationId: string, from: DateTime, until: DateTime): Promise<InferredEvent[]> => {
  const params = new URLSearchParams({
    from: from.toUTC().toISO({ suppressSeconds: true }),
    until: until.toUTC().toISO({ suppressSeconds: true }),
  });
  const response = await serenityFetch('GET', `/organisations/${organisationId}/inferred-events?${params}`, null);
  HttpResponseError.detect(response);
  const resp = await response.json() as ApiInferredEventsResponse;
  return resp.inferredEvents.map(evt => ({
    id: evt.id,
    assetId: evt.assetId,
    reportId: evt.reportId,
    reportTime: DateTime.fromISO(evt.reportTime),
    eventId: evt.eventId as InferredEventId,
  } satisfies InferredEvent));
};
