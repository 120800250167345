import { Button, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMutateDeleteEventNotificationGroup, useMutateUpdateEventNotificationGroup } from 'apis/rest/eventNotifications/hooks';
import { EventNotificationGroup } from 'apis/rest/eventNotifications/types';
import DetailPanel from 'components/shared/DetailPanel';
import { DeleteDialog } from 'components/shared/deleteDialog';
import useValidateName from 'hooks/eventNotifications/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';

interface DetailsSectionProps {
  group?: EventNotificationGroup,
  OnDelete: () => void;
}

export const DetailsSection = ({ group, OnDelete }: DetailsSectionProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.detailsSection');
  const tEN = useTranslations('pages.manage.eventNotifications');
  const snackbar = useSnackbar();
  const [name, setName] = useState<string>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>();

  const updateMutation = useMutateUpdateEventNotificationGroup();
  const deleteMutation = useMutateDeleteEventNotificationGroup();
  const nameValidation = useValidateName(group ?? null, name);

  const helperText = useMemo(
    () => (!nameValidation.valid && nameValidation.helperText ? t(`groupName.validations.${nameValidation.helperText}`) : ''),
    [nameValidation, t]
  );
  const canSaveName = useMemo(
    () => nameValidation.changed && nameValidation.valid && !nameValidation.isLoading,
    [nameValidation]
  );

  const reset = useCallback(() => setName(undefined), []);
  const saveGroup = useCallback(() => {
    if (!group) { return; }
    updateMutation.mutate({
        notificationGroup: {
          id: group.id,
          name: name?.trim() ?? group.name,
          isActive: group.isActive,
          rowVersion: group.rowVersion
        }
      },
      {
        onSuccess: () => {
          snackbar.display({
            id: `eventNotification.update.${group!.id}`,
            text: t('snackbar.success'),
            type: 'success'
          });
        },
        onError: error => {
          snackbar.display({
            id: `eventNotification.update.error.${group!.id}`,
            text: t('snackbar.updateError'),
            type: 'error'
          });
        },
      }
    );
  }, [group, name, snackbar, t, updateMutation]);

  const handleDeleteCancel = useCallback(() => setIsDeleteDialogOpen(false), []);
  const handleDeleteConfirm = useCallback(() => {
    if (group) {
      deleteMutation.mutate(group, {
        onSuccess: () => {
          snackbar.display({
            id: `eventNotificationGroup.${group?.id}.delete`,
            text: tEN('delete.successText', { name: group?.name ?? '' }),
            type: 'warning'
          });

          OnDelete();
        }
      });
    }
  }, [group, OnDelete, deleteMutation, snackbar, tEN]);

  return (
    <>
      <DetailPanel>
        <Stack spacing={3} mx={3}>
          <Stack direction="row" spacing={3}>
            <TextField
              label={t('groupName.label')}
              value={name ?? group?.name ?? ''}
              onChange={event => setName(event.target.value)}
              error={!nameValidation.valid || nameValidation.missing}
              helperText={helperText}
              disabled={updateMutation.isPending}
              inputProps={{ maxLength: 255 }}
              fullWidth />
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="flex-end" height="4rem">
            <Button variant="outlined" size="large" sx={{ minWidth: '10rem' }} onClick={() => reset()}
              disabled={!(nameValidation.changed) || updateMutation.isPending}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="contained" size="large" sx={{ minWidth: '10rem' }} onClick={() => saveGroup()}
              disabled={!canSaveName || updateMutation.isPending}>
              {t('buttons.save')}
            </Button>
          </Stack>
        </Stack>
      </DetailPanel>

      <DetailPanel mt={3}>
        <Stack direction="row" spacing={3} px={3} justifyContent="space-between" height="4em">
          <Stack justifyContent="center">
            <Typography>{t('deleteLabel')}</Typography>
          </Stack>
          <Button variant="contained" size="large" color="error" sx={{ minWidth: '10rem' }} onClick={() => setIsDeleteDialogOpen(true)}
            disabled={updateMutation.isPending}>
            {t('buttons.delete')}
          </Button>
        </Stack>
      </DetailPanel>

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title={tEN('delete.dialogTitle')}
        open={isDeleteDialogOpen ?? false}
        confirmText={deleteMutation.isPending ? tEN('delete.loadingText') : tEN('delete.confirmText')}
        snackText="">
        {tEN('delete.dialogText', { name: group?.name })}
      </DeleteDialog>
    </>
  );
};
