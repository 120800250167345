import { produce } from 'immer';
import { v4 as uuid } from 'uuid';
import { AnyAction } from 'redux';
import { VIEWS_PER_LAYOUT, DEFAULT_MAP_LAYOUT } from 'constants/maplayouts';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import { MapBaseLayerIds } from 'constants/map';
import mixpanel from 'mixpanel-browser';
import { Style } from "mapbox-gl";
import { loggedOut, resetEverything, setOrganisationId } from 'slices/session/session.slice';

/**
 * This reducer is for Map and Layer state only.  Map Settings
 * exist in the settings reducer under the 'map' key.
* */

type RGB = [number, number, number];
export type DropsOption = 'showDropTrails' | 'showDropIcons';
export type ContainmentLinesOption = 'none' | 'selectedAsset' | 'allAssets';

export interface MapSettings {
  id: string
  sortOrder: number
  baseLayerId: MapBaseLayerIds
  baseLayerLibrary: string
  animateToSelection: boolean
  animateTrails: boolean
  animateSelectedTrailOnly: boolean
  permanentLabels: boolean
  kmlLabels: boolean
  highlightSelectedObject: boolean
  unselectedItemOpacity: number
  defaultPulseColor: RGB
  followPulseColor: RGB
  assetClustering: boolean
  trailsOption: (typeof TRAILS_OPTIONS)[keyof (typeof TRAILS_OPTIONS)]
  dropsOption: DropsOption
  splineTrails: boolean
  splineTension: number
  splineSegmentCount: number
  trailWidth: number
  trailUnderlineWidth: number
  hideInactiveAssets: boolean
  inactiveSinceHours: number
  reportDots: boolean
  hideReportDotsAtZoom: number
  assetTrailColouring: 'none' | 'speed' | 'altitude' | 'battery' | 'transport' | 'latency',
  windTrails: boolean
  windVelocity: boolean
  adsbEnabled: boolean
  geofencesEnabled: boolean
  threeDEnabled: boolean
  showTrailCurtain: boolean
  markersEnabled: boolean
  containmentLinesOption: ContainmentLinesOption
}

interface ViewportSettings {
  center: [number, number]
  zoom: number
}

export interface MapState {
  maps: Record<string, MapSettings>
  viewports: Record<string, ViewportSettings>
  selectedLegs: Record<string, Leg | null>
  selectedItem: Record<string, AssetBasic | null>
  measurementMarkers: Record<string, {
    currentMeasurementMarker: { lat: number, lng: number } | null
    measurementMarkers: Record<string, { lat: number, lng: number }>
  }>
  selectedAssets: Record<string, AssetBasic | null> // TODO: check
  customLayerKml: Record<string, any> // TODO
  selectedMapId: string
  selectedMapLayout: keyof typeof VIEWS_PER_LAYOUT
  isClosable: boolean
  hiddenAssetGroups: string[]
  hiddenAssets: AssetBasic[]
  hiddenInactiveAssets: AssetBasic[]
  trailHighlight: TrailHighlight | null
  assetsAreBeingFollowedOnMaps: Record<string, boolean>
}

const defaultId = 'default';
const defaultMap: MapSettings = {
  id: defaultId,
  sortOrder: 0,
  baseLayerId: MapBaseLayerIds.MapboxSatelliteStreets,
  baseLayerLibrary: 'reactmapgl',
  animateToSelection: false,
  animateTrails: false,
  animateSelectedTrailOnly: true,
  permanentLabels: false,
  kmlLabels: true,
  highlightSelectedObject: true,
  unselectedItemOpacity: 0.75,
  defaultPulseColor: [0, 0, 0], // default pulse is black
  followPulseColor: [0, 255, 0], // follow pulse is green
  assetClustering: false,
  trailsOption: TRAILS_OPTIONS.allTrailsIcons,
  dropsOption: 'showDropIcons',
  windTrails: false,
  windVelocity: false,
  adsbEnabled: false,

  // To spline, or not to spline... and _how_ to spline...
  splineTrails: true,
  splineTension: 0.5,
  splineSegmentCount: 25,

  trailWidth: 2,
  trailUnderlineWidth: 4,
  hideInactiveAssets: false,
  inactiveSinceHours: 1,
  reportDots: true,
  hideReportDotsAtZoom: 7.5,
  assetTrailColouring: 'none',
  geofencesEnabled: false,
  threeDEnabled: false,
  showTrailCurtain: false,
  markersEnabled: true,
  containmentLinesOption: 'none',
};

const initialState: MapState = {
  maps: { default: defaultMap },
  viewports: { default: { center: [0, 0], zoom: 1 } },
  selectedLegs: { default: null },
  selectedItem: { default: null },
  measurementMarkers: { default: { currentMeasurementMarker: null, measurementMarkers: {} } },
  selectedAssets: { default: null },
  customLayerKml: { default: null },
  selectedMapId: defaultId,
  selectedMapLayout: DEFAULT_MAP_LAYOUT,
  isClosable: false,
  hiddenAssetGroups: [],
  hiddenAssets: [],
  hiddenInactiveAssets: [],
  trailHighlight: null,
  assetsAreBeingFollowedOnMaps: { default: false }
};

const persistFields = ['maps', 'viewports', 'selectedMapLayout', 'hiddenAssetGroups', 'hiddenAssets'];

const mapReducer = (state: MapState = initialState, action: AnyAction) => produce(state, () => {
  /* eslint-disable-next-line */
  switch (action.type) {
    case 'JUMP_TO_HOME_BASE': {
      const {
        mapId,
        homeBase
      } = action.payload;
      const viewport = state.viewports[mapId];

      if (!homeBase || !viewport) return state;
      return {
        ...state,
        viewports: {
          ...state.viewports,
          [mapId]: {
            ...viewport,
            ...homeBase
          }
        }
      };
    }
    case 'SET_ASSET_FOLLOW':
      return {
        ...state,
        assetsAreBeingFollowedOnMaps: {
          ...state.assetsAreBeingFollowedOnMaps,
          [action.payload.mapId]: action.payload.isFollowed
        }
      };
    case 'CLOSE_MAP': {
      const currentLayoutCount = VIEWS_PER_LAYOUT[state.selectedMapLayout];
      if (currentLayoutCount < 2) return state; // Can't close last map

      const newLayoutCount = currentLayoutCount - 1;
      const newMapLayout = (Object.keys(VIEWS_PER_LAYOUT) as (keyof typeof VIEWS_PER_LAYOUT)[]).find(key => VIEWS_PER_LAYOUT[key] === newLayoutCount);
      const remainingMaps = Object.values(state.maps)
        .filter(m => m.id !== action.payload) // Remove deleted map
        .sort((a, b) => a.sortOrder - b.sortOrder) // Ensure the remaining maps are sorted
        .reduce<Record<MapSettings['id'], MapSettings>>((acc, m, i) => {
          // Fill in the hole created by the deleted map
          acc[m.id] = {
            ...m,
            sortOrder: i
          };
          return acc;
        }, {}); // Reduce back to a id:map object

      return {
        ...state,
        selectedMapLayout: newMapLayout,
        selectedMapId: null,
        maps: remainingMaps,
        isClosable: newLayoutCount > 1
      };
    }
    case 'PATCH_VIEWPORT': {
      // This replaces UPDATE_VIEWPORT and only changes the parts of the viewport that changed.
      // If anything weird happens with the viewports in the future it may be due to this change.
      const viewport = state.viewports[action.payload.mapId];
      if (!viewport) {
        console.error('Could not find viewport while attempting to patch viewport', action.payload);
        return state;
      }

      return {
        ...state,
        viewports: {
          ...state.viewports,
          [action.payload.mapId]: {
            ...viewport,
            ...action.payload.viewport
          }
        }
      };
    }
    case 'UPDATE_MAP_CONFIG': {
      const config = state.maps[action.payload.mapId];
      if (!config) return state;

      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: {
            ...config,
            ...action.payload.config
          }
        }
      };
    }
    case 'SET_BASE_LAYER': {
      mixpanel.track('Set map base layer', { baseLayerName: action.payload.baseLayer.name });
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: {
            ...state.maps[action.payload.mapId],
            baseLayerId: action.payload.baseLayer.id,
            baseLayerLibrary: action.payload.baseLayer.library
          }
        }
      };
    }
    case 'UPDATE_MAP_LAYOUT': {
      // it is possible that the user has selected a map that will not be displayed in the new viewport layout, so we need to check for that
      // and select a different viewport if necessary - arbitrary choice, choose the first map

      const {
        layout,
        defaultMapSettings
      }: { layout: keyof typeof VIEWS_PER_LAYOUT, defaultMapSettings: MapSettings } = action.payload;

      // assign a number to the layouts, so that we can write simple(r) logic here
      const currentLayoutCount = VIEWS_PER_LAYOUT[state.selectedMapLayout];
      const newLayoutCount = VIEWS_PER_LAYOUT[layout];

      const newMaps: Record<string, MapSettings> = {};
      const newViewports: Record<string, ViewportSettings> = {};
      const newMeasurementMarkers = {};
      const newSelectedAssets = {};
      const newSelectedLegs = {};

      // General Settings (map settings) apply to each newly created map
      for (let i = Object.keys(state.maps).length; i <= newLayoutCount; i++) {
        const newMapConfigId = uuid();

        newMaps[newMapConfigId] = {
          ...defaultMapSettings,
          id: newMapConfigId,
          sortOrder: i,
          baseLayerId: MapBaseLayerIds.MapboxSatelliteStreets,
          baseLayerLibrary: 'reactmapgl',
        };
        newViewports[newMapConfigId] = { ...state.viewports.default };
        newMeasurementMarkers[newMapConfigId] = { currentMeasurementMarker: null, measurementMarkers: {} };
        newSelectedAssets[newMapConfigId] = state.selectedAssets.default ? { ...state.selectedAssets.default } : null;
        newSelectedLegs[newMapConfigId] = state.selectedLegs.default ? { ...state.selectedLegs.default } : null;
      }

      // there are two conditions that must be met to trigger a change in map selection
      // 1: reduction in map layout
      // 2: current selection will fall outside the new layout
      const resetMapSelection = (newLayoutCount < currentLayoutCount) && ((state.maps[state.selectedMapId].sortOrder + 1) > newLayoutCount);

      return {
        ...state,
        selectedMapLayout: layout,
        selectedMapId: resetMapSelection ? defaultId : state.selectedMapId,
        isClosable: newLayoutCount > 1,
        maps: {
          ...state.maps,
          ...newMaps
        },
        viewports: {
          ...state.viewports,
          ...newViewports
        },
        measurementMarkers: {
          ...state.measurementMarkers,
          ...newMeasurementMarkers
        },
        selectedAssets: {
          ...state.selectedAssets,
          ...newSelectedAssets
        },
        selectedLegs: {
          ...state.selectedLegs,
          ...newSelectedLegs
        }
      };
    }
    case 'SELECT_MAP':
      return {
        ...state,
        selectedMapId: action.payload
      };
    case 'ASSIGN_ITEM_TO_MAP': {
      let cmm = null;
      if (state.measurementMarkers[action.payload.mapId]?.measurementMarkers
        && state.measurementMarkers[action.payload.mapId]?.measurementMarkers[action.payload.item?.id]) {
        cmm = {
          lat: state.measurementMarkers[action.payload.mapId].measurementMarkers[action.payload.item?.id]?.lat,
          lng: state.measurementMarkers[action.payload.mapId].measurementMarkers[action.payload.item?.id]?.lng
        };
      }
      const assetToAssign = action.payload.item;
      return {
        ...state,
        measurementMarkers: {
          ...state.measurementMarkers,
          [action.payload.mapId]: {
            ...state.measurementMarkers[action.payload.mapId],
            currentMeasurementMarker: cmm
          }
        },
        selectedAssets: {
          ...state.selectedAssets,
          [action.payload.mapId]: assetToAssign
        },
        selectedLegs: {
          ...state.selectedLegs,
          [action.payload.mapId]: null
        },
        // turn off following on selected map when changing asset selection
        assetsAreBeingFollowedOnMaps: {
          ...state.assetsAreBeingFollowedOnMaps,
          [action.payload.mapId]: false,
        }
      };
    }
    case 'UNASSIGN_ITEM_FROM_MAP':
      if (!state.selectedMapId) return state;
      return {
        ...state,
        selectedAssets: {
          ...state.selectedAssets,
          [state.selectedMapId]: null
        },
        selectedLegs: {
          ...state.selectedLegs,
          [state.selectedMapId]: null
        }
      };
    case 'ASSIGN_MARKER_TO_ASSET':
      return {
        ...state,
        measurementMarkers: {
          ...state.measurementMarkers,
          [action.payload.mapId]: {
            ...state.measurementMarkers[action.payload.mapId],
            measurementMarkers: {
              ...state.measurementMarkers[action.payload.mapId].measurementMarkers,
              [action.payload.assetId]: {
                lat: action.payload.lat,
                lng: action.payload.lng
              }
            },
            currentMeasurementMarker: {
              lat: action.payload.lat,
              lng: action.payload.lng
            }
          }
        }
      };

    case 'SET_TRAILS_OPTION': {
      if (!action.payload.mapId) return state;
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapId]: {
            ...state.maps[action.payload.mapId],
            trailsOption: action.payload.trailsOption
          }
        }
      };
    }
    case 'HIDE_ASSETS_GROUP': {
      const hiddenGroups = [...state.hiddenAssetGroups, action.payload];
      return {
        ...state,
        hiddenAssetGroups: hiddenGroups.filter((e, i) => hiddenGroups.indexOf(e) === i)
      };
    }
    case 'HIDE_ASSET_GROUPS': {
      const hiddenGroups = [...state.hiddenAssetGroups, ...action.payload];
      return {
        ...state,
        hiddenAssetGroups: hiddenGroups.filter((e, i) => hiddenGroups.indexOf(e) === i)
      };
    }
    case 'REMOVE_FROM_HIDDEN_ASSET_GROUPS':
      return {
        ...state,
        hiddenAssetGroups: state.hiddenAssetGroups.filter(assetGroup => assetGroup !== action.payload)
      };
    case 'HIDE_ASSETS_ON_MAP': {
      const hiddenAssetsArray = [...state.hiddenAssets, ...action.payload];
      return {
        ...state,
        hiddenAssets: hiddenAssetsArray.filter((e, i) => hiddenAssetsArray.indexOf(e) === i)
      };
    }
    case 'SHOW_ASSETS_ON_MAP': {
      const assetsToShow = action.payload.map(a => a.id);
      return {
        ...state,
        hiddenAssets: state.hiddenAssets.filter(a => !assetsToShow.includes(a.id))
      };
    }
    case 'SHOW_ALL_ASSETS_ON_MAP': {
      return {
        ...state,
        hiddenAssetGroups: [],
        hiddenAssets: []
      };
    }
    case 'UPDATE_HIDDEN_INACTIVE_ASSETS': {
      return {
        ...state,
        hiddenInactiveAssets: [...action.payload]
      };
    }

    case 'SET_TRAIL_HIGHLIGHT': {
      return {
        ...state,
        trailHighlight: action.payload.highlightTrail
      };
    }

    case setOrganisationId.type: {
      const resetSelectedAssets = Object.keys(state.selectedAssets).map(mapId => ({ [mapId]: null }));
      const resetSelectedLegs = Object.keys(state.selectedLegs).map(mapId => ({ [mapId]: null }));
      return {
        ...state,
        selectedAssets: Object.assign({}, ...resetSelectedAssets),
        selectedLegs: Object.assign({}, ...resetSelectedLegs),
      };
    }

    case 'SELECT_LEG': {
      return {
        ...state,
        selectedLegs: {
          ...state.selectedLegs,
          [state.selectedMapId]: action.payload.leg
        }
      };
    }

    case loggedOut.type:
    case resetEverything.type:
      return initialState;
    default:
      return state;
  }
});

export default {
  key: 'map',
  reducer: mapReducer,
  version: 16,
  whitelist: persistFields,
  migrations: {
    5: () => initialState,
    6: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          lastNumberOfDaysReported: 1
        }
      }), {})
    }),
    7: state => ({
      ...state,
      assetsAreBeingFollowedOnMaps: { default: false }
    }),
    8: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          assetClustering: false,
        }
      }), {})
    }),
    9: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          reportDots: true,
          hideReportDotsAtZoom: 7.5,
        }
      }), {})
    }),
    10: state => ({
      ...state,
      selectedLegs: Object.keys(state.selectedLegs).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: null
      }), {})
    }),
    11: state => ({
      ...state,
      // omit layers from each existing map settings state
      maps: Object.keys(state.maps).reduce<Record<string, MapSettings>>((acc, mapId) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { layers, ...map } = state.maps[mapId];
        acc[mapId] = map;
        return acc;
      }, {}),
    }),
    12: state => ({
      ...state,
      assetTrailColouring: state.assetTrailColouring ?? 'none',
    }),
    13: state => state,
    14: state => {
      const convert = (id: MapBaseLayerIds) => {
        if (id === 'reactmapgl-here-hybrid') return MapBaseLayerIds.MapboxSatelliteStreets;
        if (id === 'reactmapgl-here-terrain') return MapBaseLayerIds.MapboxOutdoors;
        if (id === 'reactmapgl-here-dark') return MapBaseLayerIds.MapboxDark;
        return id;
      };
      return {
        ...state,
        maps: Object.keys(state.maps).reduce((acc, mapId) => {
          const map = state.maps[mapId];
          return {
            ...acc,
            [mapId]: {
              ...map,
              baseLayerId: convert(map.baseLayerId),
            },
          };
        }, {}),
      };
    },
    15: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          markersEnabled: true,
        }
      }), {})
    }),
    16: state => ({
      ...state,
      maps: Object.keys(state.maps).reduce((acc, mapId) => ({
        ...acc,
        [mapId]: {
          ...state.maps[mapId],
          containmentLinesOption: 'none',
        }
      }), {})
    })
  },
};
