import { combineReducers } from 'redux';
import { purgeStoredState, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import omniboxSettings from 'slices/settings/omniboxSettingsSlice';
import app from 'slices/app.slice';
import positions from './positions';
import map from './map';
import settings from './settings';
import ui from './ui';
import legs from './legs';
import incidents from './incidents';
import assetGroups from '../slices/assetGroups.slice';
import labs from '../slices/labs.slice';
import statsFilter from '../slices/statsFilter.slice';
import markers from '../slices/markers.slice';
import inferredEvents from '../slices/map/inferredEvents.slice';
import session, { resetEverything } from '../slices/session/session.slice';
import manageGeofences from '../slices/manageGeofences.slice';
import mapSettings from '../slices/settings/mapSettings.slice';
import unitSettings from '../slices/settings/unitSettings.slice';
import uiSettings from '../slices/settings/uiSettings.slice';
import localeSettings from '../slices/settings/localeSettings.slice';
import multiPointMeasurement from '../slices/map/multiPointMeasurement.slice';
import reports from '../slices/report.slice';

const DEBUG = true;

const purgeStore = () => {
  purgeStoredState({ key: 'app', storage });
  purgeStoredState({ key: map.key, storage });
  purgeStoredState({ key: session.key, storage });
  purgeStoredState({ key: settings.key, storage });
};

/**
 * @deprecated Just use persistReducer directly in relevant modules
 */
const wireUp = config => persistReducer({
  key: config.key,
  version: config.version,
  whitelist: config.whitelist,
  blacklist: config.blacklist,
  migrate: createMigrate(config.migrations, { debug: DEBUG }),
  stateReconciler: config.stateReconciler,
  storage,
}, config.reducer);

const rootReducer = combineReducers({
  app,
  positions,
  legs,
  map: wireUp(map),
  session: wireUp(session),
  settings: wireUp(settings),
  ui,
  reports,
  incidents,
  assetGroups,
  labs,
  statsFilter: wireUp(statsFilter),
  markers,
  inferredEvents,
  manageGeofences,
  unitSettings,
  mapSettings,
  uiSettings,
  localeSettings,
  omniboxSettings,
  multiPointMeasurement,
});

export type TRootState = ReturnType<typeof rootReducer>;

export default () => {
  const allReducers = rootReducer;
  return (state: TRootState, action: any) => {
    if (action.type === resetEverything.type) {
      purgeStore();
    }
    return allReducers(state, action);
  };
};
