import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { Box, Checkbox, FormControl, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HelpOutline } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import useFeature from 'hooks/features/useFeature';
import useFeatureFlag from 'hooks/useFeatureFlag';
import TracPlusLabsLogo from 'components/shared/labs/Logo';
import type { MapSettings } from 'reducers/map';
import { updateConfig } from 'actions/map';
import { showLabInfo } from 'slices/labs.slice';
import { MapSettingsSection, MapSettingsTitle } from '../../settingsDrawer-styles';
import ContainmentLinesOptionPicker from '../containmentLinesOptionPicker/ContainmentLinesOptionPicker';
import useFeatureAssets from "contexts/featureAssets/useFeatureAssets";

interface LabsMapSettingsProps {
  mapId: string
}

const Wrapper = styled(Box)(({ theme }) => `
  background-color: ${theme.palette.labs.light};

  ${MapSettingsTitle} {
    color: ${theme.palette.mode === 'light' ? theme.palette.labs.main : theme.palette.common.black};
  }
  
  ${MapSettingsSection} {
    border-top: 0;
  }
`);

const LabsMapSettings = ({ mapId }: LabsMapSettingsProps) => {
  const featureModules = useFeatureFlag('featureModules');
  const t = useTranslations('pages.map.settingsDialog.labs');
  const t2 = useTranslations('pages.map.settingsDialog');
  const weatherFeatureAssets = useFeatureAssets('map.weather');
  const weatherFeatureToggle = useFeature('map.weather');
  const geofencing = useFeature('manage.geofencing') && featureModules === false;
  const threeD = useFeature('map.3d') && featureModules === false;
  const markers = useFeature('map.markers') && featureModules === false;
  const containmentLines = useFeatureAssets('map.containmentLines').some && featureModules === false;

  const adsbFeatureAsset = useFeatureAssets('map.adsb');
  const showAdsb = adsbFeatureAsset.some || featureModules;
  const disableAdsb = !adsbFeatureAsset.some;

  const showWeather = weatherFeatureToggle || featureModules;
  const disableWeather = featureModules && !weatherFeatureAssets.some;

  const config = useSelector<ReduxState, MapSettings>(state => state.map.maps[mapId]);
  const dispatch = useAppDispatch();

  if (!showWeather && !showAdsb && !geofencing && !threeD && !markers && !containmentLines) return null;

  return (
    <Wrapper py={3} role="region" aria-labelledby="mapSettingsLabsHeading">
      <Box mx={3} pb={2}>
        <Box role="heading" id="mapSettingsLabsHeading"><TracPlusLabsLogo /></Box>
        <Typography fontWeight="medium">{t('description')}</Typography>
      </Box>
      {showWeather && (
        <MapSettingsSection>
          <FormControl component="fieldset" fullWidth>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t('weather.title')}</MapSettingsTitle>
              <IconButton aria-label={t('weather.help')} color="labs" onClick={() => dispatch(showLabInfo('map.weather'))}>
                <HelpOutline sx={{ fontSize: '1.8rem' }} />
              </IconButton>
            </Stack>
            <FormControlLabel
              label={t('weather.windTrails')}
              control={(
                <Checkbox
                  checked={config.windTrails}
                  onChange={event => dispatch(updateConfig(config.id, { windTrails: event.target.checked }))}
                />
              )}
              disabled={disableWeather}
            />
            <FormControlLabel
              label={t('weather.windVelocityHeatmap')}
              control={(
                <Checkbox
                  checked={config.windVelocity}
                  onChange={event => dispatch(updateConfig(config.id, { windVelocity: event.target.checked }))}
                />
              )}
              disabled={disableWeather}
            />
          </FormControl>
        </MapSettingsSection>
      )}
      {showAdsb && (
        <MapSettingsSection>
          <FormControl component="fieldset" fullWidth>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t('adsb.title')}</MapSettingsTitle>
              <IconButton aria-label={t('adsb.help')} color="labs" onClick={() => dispatch(showLabInfo('map.adsb'))}>
                <HelpOutline sx={{ fontSize: '1.8rem' }} />
              </IconButton>
            </Stack>
            <FormControlLabel
              label={t('adsb.enabled')}
              control={(
                <Checkbox
                  checked={config.adsbEnabled && !disableAdsb}
                  onChange={event => dispatch(updateConfig(config.id, { adsbEnabled: event.target.checked }))}
                />
              )}
              disabled={disableAdsb}
            />
          </FormControl>
        </MapSettingsSection>
      )}
      {geofencing && (
        <MapSettingsSection>
          <FormControl component="fieldset" fullWidth>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t2('geofences.title')}</MapSettingsTitle>
              <IconButton aria-label={t2('geofences.help')} color="labs" onClick={() => dispatch(showLabInfo('manage.geofencing'))}>
                <HelpOutline sx={{ fontSize: '1.8rem' }} />
              </IconButton>
            </Stack>
            <FormControlLabel
              label={t2('geofences.enabled')}
              control={(
                <Checkbox
                  checked={config.geofencesEnabled}
                  onChange={event => dispatch(updateConfig(config.id, { geofencesEnabled: event.target.checked }))}
                />
              )}
            />
          </FormControl>
        </MapSettingsSection>
      )}
      {threeD && (
        <MapSettingsSection>
          <FormControl component="fieldset" fullWidth>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t('3d.title')}</MapSettingsTitle>
              <IconButton aria-label={t('3d.help')} color="labs" onClick={() => dispatch(showLabInfo('map.3d'))}>
                <HelpOutline sx={{ fontSize: '1.8rem' }} />
              </IconButton>
            </Stack>
            <FormControlLabel
              label={t('3d.enabled')}
              control={(
                <Checkbox
                  checked={config.threeDEnabled}
                  onChange={event => dispatch(updateConfig(config.id, { threeDEnabled: event.target.checked }))}
                />
              )}
            />
            <FormControlLabel
              label={t('3d.curtainEnabled')}
              control={(
                <Checkbox
                  checked={config.showTrailCurtain}
                  onChange={event => dispatch(updateConfig(config.id, { showTrailCurtain: event.target.checked }))}
                />
              )}
              disabled={!config.threeDEnabled}
            />
          </FormControl>
        </MapSettingsSection>
      )}
      {markers && (
        <MapSettingsSection>
          <FormControl component="fieldset" fullWidth>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t('markers.title')}</MapSettingsTitle>
              <IconButton aria-label={t('markers.help')} color="labs" onClick={() => dispatch(showLabInfo('map.markers'))}>
                <HelpOutline sx={{ fontSize: '1.8rem' }} />
              </IconButton>
            </Stack>
            <FormControlLabel
              label={t('markers.enabled')}
              control={(
                <Checkbox
                  checked={config.markersEnabled}
                  onChange={event => dispatch(updateConfig(config.id, { markersEnabled: event.target.checked }))}
                />
              )}
            />
          </FormControl>
        </MapSettingsSection>
      )}
      {containmentLines && (
        <MapSettingsSection>
          <FormControl component="fieldset" fullWidth>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <MapSettingsTitle as="legend">{t('containmentLines.title')}</MapSettingsTitle>
              <IconButton aria-label={t('containmentLines.help')} color="labs" onClick={() => dispatch(showLabInfo('map.containmentLines'))}>
                <HelpOutline sx={{ fontSize: '1.8rem' }} />
              </IconButton>
            </Stack>
            <ContainmentLinesOptionPicker mapId={mapId} />
          </FormControl>
        </MapSettingsSection>
      )}
    </Wrapper>
  );
};

export default LabsMapSettings;
