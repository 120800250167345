import React, { useState, useEffect, useMemo } from 'react';
import { Button, Typography, InputBase } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslations } from 'use-intl';
import { sendMessage as doSendMessage } from 'apis/trackstar/trackstar';
import { useGetCapabilitiesList, useGetDeviceBasic } from 'apis/rest/devices/hooks';
import moment from 'moment';
import uuid from 'utils/uuid';
import mixpanel from 'mixpanel-browser';
import useStyles from './messageInputBox-styles';

interface MessageInputBoxProps {
  isAssetConvo: boolean;
  disabled: boolean;
  displaySnackbar: (params: Snack) => void;
  deviceId: number;
  username: string;
  organisationId: string;
  organisationName: string;
}

const MessageInputBox = ({
  isAssetConvo,
  disabled = false,
  displaySnackbar,
  deviceId,
  username,
  organisationId,
  organisationName,
}: MessageInputBoxProps) => {
  const classes = useStyles();
  const t = useTranslations('pages.messaging');
  const MAX_MESSAGE_LENGTH = isAssetConvo ? 200 : 10000;
  const queryClient = useQueryClient();
  // track number of characters in text input box
  const [charCount, setCharCount] = useState(MAX_MESSAGE_LENGTH);
  // new message text
  const [newMsg, setNewMsg] = useState('');
  // flag that tracks if new message is valid
  const [invalidText, setInvalidText] = useState(true);

  const deviceQuery = useGetDeviceBasic(deviceId).query;
  const capabilitiesQuery = useGetCapabilitiesList().query;

  const transport = useMemo(() => {
    const device = deviceQuery.data;
    if (!device || !capabilitiesQuery.data) return '';
    // NOTE: weirdness 🤨
    const messagingCapabilities = capabilitiesQuery.data.filter(c => c.scriptId === device.scriptId && c.capability === 'CP_SND_TEXT');
    if (!messagingCapabilities.length) return '';
    return messagingCapabilities.find(c => c.transport === 'IRDM_SBD')?.transport ?? messagingCapabilities[0].transport;
  }, [deviceQuery.data, capabilitiesQuery.data]);

  useEffect(() => {
    if (deviceQuery.isError) {
      displaySnackbar({
        id: 'assetLoadError',
        text: deviceQuery.error?.toString(),
        type: 'error',
      });
    }
    if (capabilitiesQuery.isError) {
      displaySnackbar({
        id: 'assetLoadError',
        text: capabilitiesQuery.error?.toString(),
        type: 'error',
      });
    }
  }, [deviceQuery, capabilitiesQuery, displaySnackbar]);

  const sendMessage = useMutation({
    mutationFn: () => doSendMessage(deviceId, `(${username}) ${newMsg}`, transport),
    onMutate: () => {
      // Optimistic update
      const prevConversation: any = queryClient.getQueryData([organisationId, 'messagesInf', deviceId]);
      const optimisticMessage = {
        content: `${organisationName}: '(${username}) ${newMsg}'`,
        conversationId: `conv-${deviceId}`,
        id: uuid(),
        isInbound: false,
        sender: { id: deviceId, ownerId: '', type: 'asset' },
        timestamp: moment().utc().format('MM/DD/YYYY HH:mm:ss'),
      };
      queryClient.setQueryData(
        [organisationId, 'messagesInf', deviceId],
        (old: any) => {
          const updatedFirstPage = [...old.pages[0], optimisticMessage];
          const restPages = old.pages.slice(1);
          return { pages: [updatedFirstPage, ...restPages], pageParams: old.pageParams };
        }
      );
      return { prevConversation };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData([organisationId, 'messagesInf', deviceId], context?.prevConversation);
      displaySnackbar({ id: 'sendMessageFailed', text: t('failed'), type: 'error' });
      mixpanel.track('Send Message', { success: false });
    },
    onSuccess: () => {
      displaySnackbar({ id: 'sendMessageSuccess', text: t('success'), type: 'success' });
      setNewMsg('');
      setCharCount(MAX_MESSAGE_LENGTH);
      mixpanel.track('Send Message', { success: true });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [organisationId, 'messagesInf', deviceId] });
    },
    mutationKey: ['sendMessage'],
  });

  useEffect(() => {
    if (newMsg?.trim() !== '') {
      setInvalidText(false);
    } else {
      setInvalidText(true);
    }
    setCharCount(MAX_MESSAGE_LENGTH - newMsg.length);
  }, [MAX_MESSAGE_LENGTH, newMsg]);

  return (
    <div className={classes.newMessage} spellCheck>
      <InputBase
        autoFocus
        className={classes.newMessageText}
        placeholder={t('inputPlaceholder')}
        multiline
        minRows={5}
        maxRows={5}
        value={newMsg}
        disabled={disabled}
        onChange={(e): void => {
          const msgLen = e.target.value.length;
          if (msgLen <= MAX_MESSAGE_LENGTH) {
            setNewMsg(e.target.value);
          }
        }}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            e.preventDefault();
            // TODO: uncomment this to enable press return to send
            // newMsg?.trim() !== '' && sendMessage.mutate();
          }
        }}
      />
      <div className={classes.charCountSendDiv}>
        {isAssetConvo && <Typography className={classes.charCount} variant="body1">{charCount}</Typography>}
        <Button
          className={classes.sendButton}
          variant="contained"
          color="primary"
          onClick={() => newMsg?.trim() !== '' && sendMessage.mutate()}
          disabled={invalidText}
        >
          {t('sendButton')}
        </Button>
      </div>
    </div>
  );
};

export default MessageInputBox;
