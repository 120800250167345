import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Button, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import StepTimeline, { TimelineElementProps } from 'components/shared/stepTimeline';
import useTimezone from 'hooks/session/useTimezone';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import { distance, speed } from 'helpers/unitsOfMeasure';
import { useGetGeocodedLocationByReportId } from 'apis/rest/geocoding/hooks';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import {
  addStatsFilterException,
  removeStatsFilterException,
  addStatsFilterInclusion,
  removeStatsFilterInclusion,
  selectStatsFilterEnabled,
  selectStatsFilterExceptions,
  selectStatsFilterInclusions,
} from 'slices/statsFilter.slice';
import useDuration from 'hooks/units/useDuration';
import useDistance from 'hooks/units/useDistance';
import { useSpeedByAssetId } from 'hooks/units/useSpeed';
import { getSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import { useTheme } from '@mui/material/styles';
import { DropGroup } from './dropGroups';
import { tripTooShort } from '../statistics';

interface DropDetailSummaryProps {
  group: DropGroup;
  dropReports: Record<number, TripSlimReport[]>;
  lastDropTime?: number;
  nextFillTime?: number;
}

type DropExt = Drop & {
  change: number;
  speed?: number;
  distance?: number;
  location?: string;
  skip: boolean;
  tripId?: string;
  isLastInTrip?: boolean;
  shouldBeFilteredFromStats?: boolean;
};

const StepView = ({ item: drop }: TimelineElementProps<DropExt>) => {
  const t = useTranslations('pages.reporting.firefighting.detailSummary');
  const tz = useTimezone();
  const dispatch = useAppDispatch();
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const duration = useDuration();

  const distanceUnits = useDistance();
  const speedUnits = useSpeedByAssetId(drop.assetId);
  const shortDistanceUnits = distanceUnits.unit === 'kilometres' ? 'metres' : 'feet';

  const statsFilterEnabled = useSelector(selectStatsFilterEnabled);
  const statsFilterExceptions = useSelector(selectStatsFilterExceptions);
  const statsFilterInclusions = useSelector(selectStatsFilterInclusions);

  if (drop.id === -1) {
    return (
      <Box display="grid" gridTemplateColumns="25ch 1fr" sx={{ gridColumnGap: 16 }} p={3}>
        <Stack direction="column" justifyContent="center">
          <Typography>{`${t('directDistance')}: ${drop.distance ? distance.withUnits(distance.fromSI(drop.distance, distanceUnits.unit), distanceUnits.unit, 1) : '—'}`}</Typography>
          <Typography fontStyle="italic">{t('directDistanceDescription')}</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography>{`${t('directFlightDuration')}: ${duration.fromMillis(drop.duration, undefined, 'm\'m\' s\'s\'')}`}</Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Paper elevation={0}>
      <Box display="grid" gridTemplateColumns="25ch 50ch 30ch 1fr 30ch" sx={{ gridColumnGap: 16 }} p={3}>
        <Stack direction="column" justifyContent="center">
          <Typography fontWeight="bold">{drop.splitDrop ? t('type.SplitDrop') : t(`type.${drop.type}`)}</Typography>
          {(drop.dropVolume || drop.change) ? (
            <Typography>{volume.create(drop.dropVolume ?? drop.change).format()} {t(`suppressant.${drop.suppressant}`)}</Typography>
          ) : (
            <Typography>{t(`suppressantNoVolume.${drop.suppressant}`)}</Typography>
          )}
        </Stack>
        <Stack direction="column" justifyContent="center">
          {!drop.location
            ? <Skeleton width="50ch" sx={{ display: 'inline-block' }} />
            : drop.location !== 'Unknown' ? <Typography>{drop.location}</Typography> : undefined}
          <Typography>{DateTime.fromMillis(drop.startTime).setZone(tz).toFormat('dd MMM yyyy, HH:mm:ss ZZZ')}</Typography>
        </Stack>
        {
          drop.type === 'Drop' ? (
            <>
              <Stack direction="column" justifyContent="center">
                <Typography>{`${t('duration')}: ${duration.fromMillis(drop.duration, undefined, 'm\'m\' s\'s\'')}`}</Typography>
                <Typography>{`${t('rate')}: ${volumePerPeriod.create((drop.dropVolume ?? 0) / (drop.duration / 1000), 's').format()}`}</Typography>
              </Stack>
              <Stack direction="column" justifyContent="center">
                <Typography>{`${t('speed')}: ${drop.speed ? speed.withUnits(speed.fromKmh(drop.speed, speedUnits), speedUnits) : '—'}`}</Typography>
                <Typography>{`${t('distance')}: ${drop.distance ? distance.withUnits(distance.fromSI(drop.distance, shortDistanceUnits), shortDistanceUnits) : '—'}`}</Typography>
              </Stack>
              {
                drop.tripId && statsFilterEnabled && drop.isLastInTrip && (
                  <Stack direction="column" justifyContent="center">
                    {
                      drop.shouldBeFilteredFromStats ? (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {
                            !statsFilterExceptions.includes(drop.tripId) ? (
                              <Button onClick={() => drop.tripId && dispatch(addStatsFilterException(drop.tripId))}>
                                {t('statsFilter.allow')}
                              </Button>
                            ) : (
                              <Button color="error" onClick={() => drop.tripId && dispatch(removeStatsFilterException(drop.tripId))}>
                                {t('statsFilter.disallow')}
                              </Button>
                            )
                          }
                        </>
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {
                            !statsFilterInclusions.includes(drop.tripId) ? (
                              <Button color="error" onClick={() => drop.tripId && dispatch(addStatsFilterInclusion(drop.tripId))}>
                                {t('statsFilter.disallow')}
                              </Button>
                            ) : (
                              <Button onClick={() => drop.tripId && dispatch(removeStatsFilterInclusion(drop.tripId))}>
                                {t('statsFilter.allow')}
                              </Button>
                            )
                          }
                        </>
                      )
                    }
                  </Stack>
                )
              }
            </>
          ) : (
            <Stack direction="column" justifyContent="center">
              <Typography>{`${t('containerVolume')}: ${volume.create(drop.endVolume).format()}`}</Typography>
            </Stack>
          )
        }
      </Box>
    </Paper>
  );
};

const DropDetailSummary = ({ group: { drops, trip }, dropReports, lastDropTime, nextFillTime }: DropDetailSummaryProps) => {
  const t = useTranslations('pages.reporting.firefighting.detailSummary');
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const durationUnit = useDuration();

  const theme = useTheme();
  const suppressantColor = getSuppressantColors(theme);

  const flightTimeMillis = useMemo(() => {
    const firstFill = drops.find(d => d.type === 'Fill');
    const lastDrop = drops.slice(0).reverse().find(d => d.type === 'Drop');

    if (!firstFill || !lastDrop) {
      return 0;
    }

    return (lastDrop.endTime ?? lastDrop.startTime) - firstFill.startTime;
  }, [drops]);
  const totalDropVolume = useMemo(() => drops.reduce<number>((acc, drop) => acc + (drop.dropVolume ?? 0), 0), [drops]);
  const averageDropRate = useMemo(() => {
    const duration = drops.filter(d => d.type === 'Drop').reduce<number>((acc, drop) => acc + drop.duration, 0);
    return duration > 0 ? totalDropVolume / (duration / 1000) : 0;
  }, [drops, totalDropVolume]);

  const { lastDropDuration, nextFillDuration } = useMemo(() => {
    const lastMs = lastDropTime ? drops[0].startTime - lastDropTime : 0;
    const nextMs = nextFillTime ? nextFillTime - drops[drops.length - 1].startTime : 0;
    const last = lastMs > 0 ? durationUnit.fromMillis(lastMs, undefined, durationUnit.findFormat(lastMs)) : undefined;
    const next = nextMs > 0 ? durationUnit.fromMillis(nextMs, undefined, durationUnit.findFormat(nextMs)) : undefined;
    return { lastDropDuration: last, nextFillDuration: next };
  }, [drops, lastDropTime, nextFillTime, durationUnit]);

  const coordsToName = useMemo(() => drops.reduce<Pick<Report, 'id' | 'latitude' | 'longitude'>[]>((acc, drop) => {
    const report = dropReports[drop.id]?.at(0);
    if (report) {
      acc.push({
        id: report.id,
        longitude: report.coords[0],
        latitude: report.coords[1],
      });
    }
    return acc;
  }, []), [drops, dropReports]);
  const locationsQuery = useGetGeocodedLocationByReportId(drops.at(0)?.assetId, coordsToName).query;

  const extDrops: DropExt[] = useMemo(() => {
    const lastDrop = trip?.drops.slice(0).reverse().find(d => d.type === 'Drop');
    const shouldBeFiltered = trip ? tripTooShort(trip.drops) : false;
    const list: DropExt[] = drops.map((drop, idx) => {
      const change = idx > 0
        ? (drop.endVolume ?? 0) - (drops[idx - 1].endVolume ?? 0)
        : (drop.endVolume ?? 0);

      const [start, stop] = dropReports[drop.id] ?? [undefined, undefined];

      const speedStart = start && start.speed > 0 ? start.speed : undefined;
      const speedStop = stop && stop.speed > 0 ? stop.speed : undefined;
      const avgSpeed = speedStart && speedStop ? (speedStart + speedStop) / 2 : (speedStart ?? speedStop);

      return ({
        ...drop,
        change: change > 0 ? change : 0,
        speed: avgSpeed,
        distance: stop?.distance,
        location: !locationsQuery.isLoading && locationsQuery.data ? locationsQuery.data[start?.id] ?? 'Unknown' : undefined,
        skip: false,
        tripId: trip?.id,
        isLastInTrip: lastDrop ? lastDrop.id === drop.id : false,
        shouldBeFilteredFromStats: shouldBeFiltered,
      });
    });

    if (list.length > 1) {
      const firstDropIndex = list.findIndex(d => d.type === 'Drop');
      const firstFill = list.find(d => d.type === 'Fill');
      if (firstDropIndex > -1 && firstFill) {
        const firstDropReports = dropReports[list[firstDropIndex].id];
        const firstFillReports = dropReports[firstFill.id];
        if (firstDropReports && firstDropReports[0] && firstFillReports && firstFillReports[0]) {
          list.splice(firstDropIndex, 0, ({
            ...list[firstDropIndex - 1],
            id: -1,
            distance: distance.distanceTo(
              firstFillReports[0].coords[1],
              firstFillReports[0].coords[0],
              firstDropReports[0].coords[1],
              firstDropReports[0].coords[0]
            ),
            duration: firstDropReports[0].timeOfFix - firstFillReports[0].timeOfFix,
            skip: true,
          }));
        }
      }
    }

    return list;
  }, [trip, drops, dropReports, locationsQuery.isLoading, locationsQuery.data]);

  return (
    <Box display="flex" flexDirection="column" gap={3} pb={3}>
      <Box>
        <Box display="flex" flexDirection="row" gap={8} alignItems="start">
          <Box>
            <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('totalFlightTime')}</Typography>
            <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
              {flightTimeMillis < 60 * 1000
                ? durationUnit.fromMillis(flightTimeMillis, undefined, 's\'s\'')
                : durationUnit.fromMillis(flightTimeMillis, undefined, 'm\'m\' s\'s\'')}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('totalDropVolume')}</Typography>
            <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
              {totalDropVolume ? volume.create(totalDropVolume).format() : '—'}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="1rem" lineHeight={1} fontWeight="normal" textTransform="uppercase">{t('averageDropRate')}</Typography>
            <Typography fontSize="2.5rem" lineHeight={1.2} fontWeight="bold">
              {volumePerPeriod.create(averageDropRate, 's').format()}
            </Typography>
          </Box>
          {lastDropDuration && <Typography flexGrow={1} align="right">{`${lastDropDuration} ${t('afterLastDrop')}`}</Typography>}
        </Box>
        <Typography fontSize="0.9rem" lineHeight={1} fontWeight="normal" fontStyle="italic">{t('flightTimeDescription')}</Typography>
      </Box>
      <StepTimeline steps={extDrops} stepView={StepView} colorFn={d => suppressantColor[d.suppressant]} />
      {nextFillDuration && <Typography align="right">{`${nextFillDuration} ${t('beforeNextFill')}`}</Typography>}
    </Box>
  );
};

export default DropDetailSummary;
