import React, { useCallback } from 'react';
import { updateSetting } from 'actions/settings';
import { Button, Chip } from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import BarChartIcon from '@mui/icons-material/BarChart';
import useFeature from 'hooks/features/useFeature';
import mixpanel from 'mixpanel-browser';
import { useAppDispatch } from 'store/types';
import { useSelector } from 'react-redux';

type Query = ReduxState['settings']['tripAnalysis']['query'];

interface ButtonProps {
  assetId: number
  startOfDay: DateTime
}

export const ViewInTripAnalysis = ({ assetId, startOfDay }: ButtonProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = useTranslations('contextbox.asset.activity.actionButtons');
  const setQuery = useCallback((newQuery: Query) => dispatch(updateSetting('tripAnalysis', 'query', newQuery)), [dispatch]);
  const selectedLeg = useSelector<ReduxState, Leg | null>(state => state.map.selectedLegs[state.map.selectedMapId]);
  const onClick = useCallback(() => {
    mixpanel.track('Trip Analysis click through from map');
    setQuery({
      assets: [assetId],
      from: startOfDay.toMillis(),
      until: startOfDay.endOf('day').toMillis()
    });
    navigate('/insights/trip-analysis', { state: { selectedAssetId: assetId, findTripAround: selectedLeg ? ((selectedLeg.end + selectedLeg.start) / 2) * 1000 : undefined } });
  }, [setQuery, assetId, startOfDay, navigate, selectedLeg]);

  const tripAnalysis = useFeature('reporting.newTripReports');

  if (!tripAnalysis) {
    return null;
  }

  return (
    <Button fullWidth variant="contained" startIcon={<BarChartIcon />} onClick={onClick}>
      {t('viewInTripAnalysisTooltip')}
      <Chip
        variant="outlined"
        label={t('beta')}
        color="primary"
        size="small"
        sx={{ border: 0, backgroundColor: 'common.white', textTransform: 'uppercase', fontWeight: 'bold', ml: 1 }}
      />
    </Button>
  );
};
