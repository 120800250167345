import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate, persistReducer, PersistState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loggedOut, resetEverything, setOrganisationId } from './session/session.slice';

const queries = [
  {
    name: 'Assets',
    graphql: '', // graphqlAssetsQuery,
    query: 'type:asset',
    icon: 'A'
  },
  {
    name: 'Devices',
    graphql: '', // graphqlDevicesQuery,
    query: 'type:device',
    icon: 'D'
  }
];

export interface AppState {
  selectedItem: AssetBasic | null
  // grouping for each type of omnibox result
  groupBy: {
    assets: 'latestActivity' | 'make' | 'model' | 'owner' | 'category' | 'watchlistGroup' | 'operatorName' | 'assetGroup'
    devices: 'none' | 'make' | 'model' | 'status'
    missions: 'none' | 'type'
  }
  sortBy: {
    assets: 'activity' | 'name'
    devices: 'none'
    missions: 'none'
  }
  query: Query,
  queries: Query[],
  // Timezones for any clocks that to be displayed
  clocks: string[]
  // Day in ISO-8602 calendar date format YYYY-MM-DD or null for the current day
  selectedDay: string | null
  // application notifications (NOT snackbar notifications)
  notifications: []
  // snackbar notifications at bottom of screen
  snackbars: Record<string, Snack>
  _persist: PersistState
}

const initialState: Readonly<AppState> = {
  // TODO: this was populated by a GQl query
  groupBy: {
    assets: 'latestActivity',
    devices: 'none',
    missions: 'none'
  },
  sortBy: {
    assets: 'activity',
    devices: 'none',
    missions: 'none'
  },
  query: queries[0],
  queries,
  clocks: ['utc'],
  selectedDay: null,
  notifications: [],
  snackbars: {},
  selectedItem: null,
  _persist: {
    version: 0,
    rehydrated: false,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    selectItem: (state, action: PayloadAction<AssetBasic | AssetWithDevice | null>) => {
      state.selectedItem = action.payload;
    },
    deleteAsset: (state, action: PayloadAction<Pick<AssetBasic, 'id'>>) => {
      if (state.selectedItem?.id === action.payload.id) {
        state.selectedItem = null;
      }
    },
    clearSelection: state => {
      state.selectedItem = null;
    },
    setOmniboxGroupBy: (state, action: PayloadAction<AppState['groupBy']>) => {
      state.groupBy = action.payload;
    },
    setOmniboxSortBy: (state, action: PayloadAction<AppState['sortBy']>) => {
      state.sortBy = action.payload;
    },
    setDay: (state, action: PayloadAction<AppState['selectedDay']>) => {
      state.selectedDay = action.payload ?? null;
    },
    addClock: (state, action: PayloadAction<string>) => {
      state.clocks = [...new Set([...state.clocks, action.payload])];
    },
    removeClock: (state, action: PayloadAction<string>) => {
      state.clocks = state.clocks.filter(c => c !== action.payload);
    },
    displaySnackbar: (state, action: PayloadAction<Snack>) => {
      state.snackbars[action.payload.id] = { ...action.payload };
    },
    destroySnackbar: (state, action: PayloadAction<{ id: string }>) => {
      delete state.snackbars[action.payload.id];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setOrganisationId, state => {
        state.selectedItem = null;
      })
      .addCase(loggedOut, state => {
        state.selectedItem = null;
        state.notifications = [];
      })
      .addCase(resetEverything, () => initialState);
  },
  selectors: {
    getSelectedDay: state => state.selectedDay,
    getSelectedItem: state => state.selectedItem,
  }
});

export const {
  selectItem,
  deleteAsset,
  clearSelection,
  setOmniboxGroupBy,
  setOmniboxSortBy,
  setDay,
  addClock,
  removeClock,
  displaySnackbar,
  destroySnackbar,
} = appSlice.actions;

export const {
  getSelectedDay,
  getSelectedItem,
} = appSlice.selectors;

// Persistence configuration
export default persistReducer({
  key: 'app',
  version: 5,
  whitelist: ['clocks', 'groupBy', 'sortBy'],
  migrate: createMigrate({
    0: () => initialState,
    1: (state: any) => ({
      ...state,
      availableQueries: null
    }),
    2: (state: any) => ({
      ...state,
      groupBy: {
        assets: 'latestActivity',
        devices: 'none',
        missions: 'none'
      }
    }),
    3: (state: any) => ({
      ...state,
      selectedLeg: null
    }),
    4: (state: any) => ({
      ...state,
      performanceMode: true,
    }),
    5: (state: any) => ({
      ...state,
      sortBy: initialState.sortBy,
    }),
    6: (state: any) => ({
      ...state,
      performanceMode: false,
    }),
  }, { debug: true }),
  storage,
}, appSlice.reducer);
