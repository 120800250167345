import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import AnalysisboxContainer from 'components/analysisboxContainer';
import AssetContextbox from 'components/contextbox/asset';
import CreateMarkerDialog from 'components/dialogs/markers/editDialog';
import MapContainer from 'components/mapContainer';
import Omnibox from 'components/omnibox';
import Page from 'components/pages/page';
import { closeCreateMarkerDialog, selectCreateMarkerDialog, setIsPlacingMarker } from 'slices/markers.slice';
import { useCreateMarker } from 'apis/rest/markers/hooks';
import moment from 'moment';
import useTimezone from 'hooks/session/useTimezone';
import { getSelectedDay, getSelectedItem } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import useStyles from './map-styles';
import useOrganisationId from 'hooks/session/useOrganisationId';

const MapPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const t = useTranslations('dialogs.markers.create');
  const createMarkerDialog = useSelector(selectCreateMarkerDialog);
  const createMarkerMutation = useCreateMarker();

  const selectedItem = useSelector(getSelectedItem);
  const organisationId = useOrganisationId();
  const contextboxOpen = useSelector(state => state.ui.contextboxOpen);


  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);

  return (
    <Page>
      <Omnibox />

      <Stack className={classes.mapWrapper}>
        <MapContainer key={organisationId} />
        <AnalysisboxContainer selectedAsset={selectedItem} />
      </Stack>

      {selectedItem && <AssetContextbox selectedAsset={selectedItem} now={now} contextboxOpen={contextboxOpen} />}

      <CreateMarkerDialog
        open={createMarkerDialog.open}
        title={t('title')}
        latLng={createMarkerDialog.latLng}
        onClose={() => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
        }}
        onSubmit={marker => {
          dispatch(setIsPlacingMarker(false));
          dispatch(closeCreateMarkerDialog());
          createMarkerMutation.mutate({ marker });
        }}
      />

    </Page>
  );
};

export default MapPage;
