import { setOmniboxGroupBy } from 'slices/app.slice';

// Re-export here for backward compatability
export { setOmniboxGroupBy };

export const selectLeg = leg => ({
  type: 'SELECT_LEG',
  payload: {
    leg
  }
});
const SNACKBAR_TYPES = {
  info: true,
  error: true,
  warning: true,
  success: true
};

/**
 * @deprecated Use hooks/useSnackbar instead
 */
export const displaySnackbar = ({
  id,
  text,
  type = 'info',
  ...options
}) => dispatch => {
  if (!id) throw new Error('Cannot create a Snackbar without an ID.');
  if (!SNACKBAR_TYPES[type]) throw new Error(`Unknown Snackbar Type: '${type}'`);

  dispatch({
    type: 'DISPLAY_SNACKBAR',
    payload: {
      id,
      text,
      type,
      ...options
    }
  });
};

/**
 * @deprecated Use hooks/useSnackbar instead
 */
export const destroySnackbar = id => ({
  type: 'DESTROY_SNACKBAR',
  payload: {
    id
  }
});
