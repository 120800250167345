import { minBy, maxBy } from 'lodash/fp';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GeocodedLocation, reverseGeocodeBatch } from '../apis/rest/geocoding/requests';
import { eventDoesntMakeSense } from './events';

export const eventMap = {
  EVT_STARTUP: 'S',
  EVT_SHUTDOWN: 's',
  EVT_TAKEOFF: 'T',
  EVT_LANDING: 'L',
  EVT_ENGINEON: 'S',
  EVT_ENGINEOFF: 's'
};
export const legEventTypes = Object.keys(eventMap).join(',');
export const stopEvents = [eventMap.EVT_ENGINEOFF, eventMap.EVT_SHUTDOWN, eventMap.EVT_LANDING];

const reducePrecision = (coord?: number): number => +parseFloat(String(coord)).toFixed(5);
const reduceReportPrecision = (reports: Report[], leg: LegRaw): {startLat: number; startLon: number; endLat: number, endLon: number} => {
  const validReports = reports.filter(r => r.isValid && r.longitude !== 0 && r.latitude !== 0);
  const firstValidReport = minBy(r => r.received, validReports);
  const lastValidReport = maxBy(r => r.received, validReports);

  return ({
    startLat: reducePrecision(firstValidReport?.latitude),
    startLon: reducePrecision(firstValidReport?.longitude),
    endLat: reducePrecision(leg.end !== undefined ? lastValidReport?.latitude : undefined),
    endLon: reducePrecision(leg.end !== undefined ? lastValidReport?.longitude : undefined)
  });
};
// Regex that define legs, once mapped using eventMap
const legRegex = /S?TL(?!s)|TS*Ls?|Ss|STLs/g;

interface LegRaw {
  start: number;
  end?: number;
  takeoff?: number;
  landing?: number
}

const processLegs = async (legsStringMapped: string, legIndices: number[], reports: Report[], existingLegs: Leg[], assetCategory: string): Promise<Leg[]> => {
  let lastEvent = 0;
  let firstEvent = legIndices.length;
  const geocodingCache: GeocodedLocation[] = localStorage?.geocodingCache ? JSON.parse(localStorage?.geocodingCache).filter((g: GeocodedLocation) => g.category) : [];

  const legMatches: LegRaw[] = [...legsStringMapped.matchAll(legRegex)].map(match => {
    const legStart = match.index || 0;
    const legEnd = legStart + match[0].length - 1;
    lastEvent = Math.max(lastEvent, legEnd);
    firstEvent = Math.min(firstEvent, legStart);

    const indices: LegRaw = {
      start: legIndices[legStart],
      end: legIndices[legEnd],
    };

    return indices;
  });

  const lastIndex = legMatches.at(-1)?.end ?? -1;
  if (lastIndex !== reports.length - 1) {
    // find start event after last complete leg
    const startAfter = legIndices.filter((ri, si) => ri > lastIndex && !stopEvents.includes(legsStringMapped[si])).at(0);
    if (startAfter !== undefined) {
      legMatches.push({ start: startAfter });
    }
  }

  if (legMatches.length === 0 && reports.length > 1) {
    const firstReportIsShutdown = legIndices.at(0) === 0 && stopEvents.includes(legsStringMapped.at(0) ?? '');
    if (!firstReportIsShutdown) {
      legMatches.push({ start: 0, end: legIndices.at(0) });
    }
  }

  // 1. make list of leg lat/lons that aren't in geocodingCache
  const locationsToGeocode: {latitude: number, longitude: number}[] = [];
  // TODO: this is horrifically inefficient, needs an implementation that doesn't have 4 nested loops
  legMatches.forEach(leg => {
    const legReports = reports.slice(leg.start, leg.end === undefined ? undefined : leg.end + 1);

    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(legReports, leg);
    const cachedStartLocation = geocodingCache.find(g => g.lat === startLat && g.lon === startLon && g.category === assetCategory) || locationsToGeocode.find(g => g.latitude === startLat && g.longitude === startLon);
    const cachedEndLocation = geocodingCache.find(g => g.lat === endLat && g.lon === endLon && g.category === assetCategory) || locationsToGeocode.find(g => g.latitude === endLat && g.longitude === endLon);
    if (!cachedStartLocation) locationsToGeocode.push({ latitude: startLat, longitude: startLon });
    if (!cachedEndLocation) locationsToGeocode.push({ latitude: endLat, longitude: endLon });
  });

  // 2. fetch locations for above list and add it to cache
  const validLocationsToGeocode = locationsToGeocode.filter(l => !Number.isNaN(l.latitude) && !Number.isNaN(l.longitude));
  const geocodedLocations = validLocationsToGeocode.length > 0 ? await reverseGeocodeBatch(assetCategory, validLocationsToGeocode) : [];
  const updatedCache = geocodingCache.concat(geocodedLocations);
  localStorage.setItem('geocodingCache', JSON.stringify(updatedCache));

  // 3. return legs with locations from geocodingCache
  return legMatches.flatMap(leg => {
    // return existing leg if it exists instead of requesting geocoding again
    const existingLeg = existingLegs?.length && existingLegs?.find(l => l.id === reports[leg.start].id);
    if (existingLeg) return [];

    const legReports = reports.slice(leg.start, leg.end === undefined ? undefined : leg.end + 1);

    // reject if no reports in the leg have a valid position
    if (legReports.every(r => !r.isValid)) return [];

    const takeoff = legReports.find(r => r.events.includes('EVT_TAKEOFF'));
    const landing = [...legReports].reverse().find(r => r.events.includes('EVT_LANDING'));

    // get from/to from cache and return
    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(legReports, leg);
    const from = updatedCache.find(g => g.lat === startLat && g.lon === startLon)?.location;
    const to = updatedCache.find(g => g.lat === endLat && g.lon === endLon)?.location;

    // If this leg doesn't have an end, use the time of the most recent report as the end for accurate elapsed time
    const endReport = legReports.at(-1);

    return [{
      id: reports[leg.start].id,
      deviceId: reports[leg.start].deviceId,
      assetId: reports[leg.start].assetId,
      start: reports[leg.start].received,
      end: endReport!.received,
      from,
      to,
      complete: leg.end !== undefined && leg.end !== leg.start,
      takeoff: takeoff?.received || null,
      landing: landing?.received || null,
      reports: {
        start: reports[leg.start],
        end: endReport!,
        takeoff,
        landing,
      },
    }];
  });
};

const preProcessLegs = (reports: Report[], existingLegs: Leg[], assetCategory: string, deviceMake: string): Promise<Leg[]> => {
  if (reports.length === 0) return Promise.resolve([]);
  // Create string representation of leg starts/ends
  // Create list of indices of reports relating to string representation
  let legStringMapped = '';
  const legIndices: number[] = [];
  const reportsAsc = reports.slice(0).sort((a, b) => a.received - b.received);
  reportsAsc.forEach((report, index) => {
    if (report.events[0] && Object.keys(eventMap).includes(report.events[0]) && !eventDoesntMakeSense(report, assetCategory, deviceMake)) {
      // @ts-ignore
      const mapped = eventMap[report.events[0]];
      // compress SSss to Ss, with the last S and the first s
      if (legStringMapped.at(-1) !== mapped) {
        legStringMapped += mapped;
        legIndices.push(index);
      } else {
        legIndices[legIndices.length - 1] = index;
      }
    }
  });
  return processLegs(legStringMapped, legIndices, reportsAsc, existingLegs, assetCategory);
};

export const getLegsQueryKey = (reportsForAsset: Report[]) => ['legs', reportsForAsset[0]?.deviceId, reportsForAsset.length, maxBy('received', reportsForAsset)?.received];
export const getLegsQueryFn = (selectedAsset: AssetWithDevice, reportsForAsset: Report[]) => (() => preProcessLegs(reportsForAsset, [], selectedAsset.category, selectedAsset.deviceMake));

export const useQueryLegs = <T = Leg[]>(selectedAsset: AssetWithDevice, reportsForAsset: Report[], options: Omit<UseQueryOptions<Leg[], unknown, T>, 'queryFn' | 'queryKey'>) => useQuery({
  queryFn: getLegsQueryFn(selectedAsset, reportsForAsset),
  queryKey: getLegsQueryKey(reportsForAsset),
  ...options,
});
export default preProcessLegs;
