/* eslint-disable no-underscore-dangle */
import React, {
  useMemo, useCallback, useEffect, useRef, useState
} from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import { useLatestPositionsForAssets } from 'repositories/reports/hooks';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import useTimezone from 'hooks/session/useTimezone';
import { useSelector } from 'react-redux';
import { getSelectedDay, clearSelection } from 'slices/app.slice';
import { unassignItemFromMap } from 'actions/map';
import { useAppDispatch } from 'store/types';
import QueryResult from '../queryResult';
import useStyles from '../results-styles';

// Retrieves the translated name of the bucket for the given AssetState
// Prioritises distress > ams > airborne > engine
// const getBucketNameForState = (as, t) => (as
//   ? t((as.distress || as.ams || as.airborne || as.engine)?.translationKey || 'unknown')
//   : t('unknown')
// );

interface GroupedQueryResultsProps {
  selectedItemId: number | undefined
  results: AssetBasic[]
  now: moment.Moment | null
  hiddenInactiveAssets: number[]
  hiddenAssets: AssetBasic[]
  groupBy: string
  sortBy: string
  hideAssetsGroup: (group: string) => void
  removeFromHiddenAssetGroups: (group: string) => void
  hideAssetsOnMap: (assets: AssetBasic[]) => void
  showAssetsOnMap: (assets: AssetBasic[]) => void
  hiddenAssetGroups: string[]
  selectedItem: AssetBasic
  selectAsset: (asset: AssetBasic) => void
  activeQuery: { name: string }
}

interface BucketHeader {
  type: 'header'
  bucket: string
  visibilityIcon: React.ReactNode
  height: number
}

interface BucketResult {
  type: 'assetResult',
  result: AssetBasic
  isHidden: boolean
  isSelected: boolean
  height: number
}

const GroupedQueryResults = ({
  // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
  // assetState,
  hiddenInactiveAssets,
  hiddenAssetGroups,
  hideAssetsGroup,
  activeQuery,
  removeFromHiddenAssetGroups,
  hideAssetsOnMap,
  showAssetsOnMap,
  results,
  groupBy,
  sortBy,
  selectedItemId,
  hiddenAssets,
  selectAsset,
}: GroupedQueryResultsProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('omnibox.modules.groupQueryResults');
  const t2 = useTranslations('omnibox.modules.results');
  const listRef = useRef<List | null>();
  const dispatch = useAppDispatch();

  const latestPositionsByAsset = useLatestPositionsForAssets(results);
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);

  const getBucketNameForLatestActivity = useCallback(latestActivity => {
    const now = selectedDay ? moment.tz(selectedDay, timezone) : moment();
    if (latestActivity.isAfter(now.clone().subtract(15, 'minutes'))) return t('timeBuckets.fifteenMinutes');
    if (latestActivity.isAfter(now.clone().subtract(1, 'hour'))) return t('timeBuckets.hour');
    if (latestActivity.isAfter(now.clone().subtract(1, 'day'))) return t('timeBuckets.day');
    if (latestActivity.isAfter(now.clone().subtract(1, 'week'))) return t('timeBuckets.week');
    if (latestActivity.isAfter(now.clone().subtract(1, 'month'))) return t('timeBuckets.month');
    return t('timeBuckets.older');
  }, [selectedDay, timezone, t]);

  const assetLabel = useAssetLabel();

  // group into buckets
  const buckets: Record<string, AssetBasic[]> = useMemo(() => {
    const sortedResults = [...results]
      // .filter(asset => latestPositionsByAsset[asset.id]?.received !== undefined)
      .sort((assetA, assetB) => {
        switch (sortBy) {
          case 'activity':
            return (latestPositionsByAsset[assetB.id]?.received || 0) - (latestPositionsByAsset[assetA.id]?.received || 0);
          case 'name':
          default:
            return assetLabel(assetA, '').localeCompare(assetLabel(assetB, ''));
        }
      });

    return sortedResults.reduce((acc, r) => {
      // @ts-ignore
      let field = r[groupBy];
      let time;
      switch (groupBy) {
        // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
        // case 'status':
        //   field = getBucketNameForState(assetState[r.id], tTag);
        //   break;
        case 'category':
          field = r.category;
          break;
        case 'owner':
          field = r.ownerName;
          break;
        case 'latestActivity':
          time = latestPositionsByAsset[r.id]?.received;
          field = time
            ? getBucketNameForLatestActivity(moment.unix(time))
            : t('timeBuckets.never');
          break;
        case 'assetGroup':
          if (assetGroupsQuery.isLoading) {
            field = t('loading');
          } else {
            // because we are reducing on the sorted results the UI will never show assets groups which have no assets, if this is undesired we need to change the way we are grouping
            const filteredAssetGroups = assetGroupsQuery.data?.filter(ag => ag.assets.some((x: AssetBasic) => x.id === r.id)) ?? [];

            filteredAssetGroups.forEach(ag => {
              acc[ag.name] = acc[ag.name] || [];
              if (!acc[ag.name].includes(r)) {
                acc[ag.name].push(r);
              }
            });

            if (filteredAssetGroups.length > 0) {
              // this just makes sure the device doesn't also get put in the other bucket down below if its already been put into at least one asset group
              field = 'InAssetGroup';
            }
          }
          break;
        default:
          // do nuthing
          break;
      }

      // case for asset group already handles adding device to accumulator
      if (field && groupBy !== 'assetGroup') {
        acc[field] = acc[field] || [];
        acc[field].push(r);
      }
      // When grouping the assets, if the relevant field on the asset is not set or blank, we still want to show the
      // asset in the omnibox and in an appropriate group, so we sort these unknowns into a special bucket.
      // This ensures assets with blank 'make' and 'model' fields are still shown and grouped correctly in the 'other' bucket.
      // Similary, assets with unknown/no status end up in the special bucket.
      if (!field || field.toString()
        .trim() === '') {
        const unknownBucket = t('other');
        acc[unknownBucket] = acc[unknownBucket] || [];
        acc[unknownBucket].push(r);
      }

      return acc;
    }, {} as Record<string, AssetBasic[]>);
  }, [latestPositionsByAsset, getBucketNameForLatestActivity, groupBy, sortBy, results, t, assetLabel, assetGroupsQuery]);

  // if grouping by latestActivity then use order defined in timeBucketOrder, otherwise sort groups alphabetically
  // if grouping by asset group sort alphabetically except put other last
  const sortedBuckets = useMemo(() => {
    const timeBucketOrder = ['fifteenMinutes', 'hour', 'day', 'week', 'month', 'older', 'never'];
    if (groupBy === 'latestActivity') {
      return timeBucketOrder.map(bucket => t(`timeBuckets.${bucket}`));
    }

    if (groupBy === 'assetGroup') {
      return [
        ...Object.keys(buckets)
          .sort()
          .filter(x => x !== t('other')),
        t('other')
      ];
    }

    return Object.keys(buckets).sort();
  }, [groupBy, buckets, t]);

  const toggleVisibility = useCallback((e: React.MouseEvent, groupName: string): void => {
    e.stopPropagation();

    const assetsToBeToggledOnMap = buckets[groupName].filter(a => !hiddenInactiveAssets.includes(a.id));

    if (!hiddenAssetGroups.includes(groupName) && !buckets[groupName].every(asset => !!hiddenAssets.find(a => a.id === asset.id))) {
      // because we have a new group type "Asset Group" and each asset can be part of more than one group, we can't just blindly toggle the devices visibility,
      // and have to check if they are not also not part of another bucket that is also visible

      const otherVisibleEntries = Object.entries(buckets)
        .filter(([bucketName]) => bucketName !== groupName && !hiddenAssetGroups.includes(bucketName))
        .flatMap(x => x[1])
        .map(x => x.id);
      const assetsToBeHiddenOnMap = assetsToBeToggledOnMap.filter(x => !otherVisibleEntries.includes(x.id));

      hideAssetsGroup(groupName);
      hideAssetsOnMap(assetsToBeHiddenOnMap);
    } else {
      removeFromHiddenAssetGroups(groupName);
      showAssetsOnMap(assetsToBeToggledOnMap);
    }
  }, [buckets, hiddenAssetGroups, hiddenInactiveAssets, hideAssetsGroup, hideAssetsOnMap, removeFromHiddenAssetGroups, showAssetsOnMap, hiddenAssets]);

  const displayVisibilityIcon = useCallback((groupName: string): JSX.Element | null => {
    if (!buckets[groupName]) return null;

    if (groupBy !== 'assetGroup' && buckets[groupName].every(asset => hiddenInactiveAssets.includes(asset.id))) {
      return <VisibilityOff className={classes.visibilityIconDisabled} />;
    }

    if (hiddenAssetGroups.includes(groupName) || buckets[groupName].every(asset => hiddenAssets.find(a => a.id === asset.id))) {
      if (selectedItemId && hiddenAssets.find(a => a.id === selectedItemId)) {
        dispatch(clearSelection());
        dispatch(unassignItemFromMap());
      }
      return (
        <Tooltip title={t('showOnMap')}>
          <Visibility onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title={t('hideOnMap')}>
        <VisibilityOff onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
      </Tooltip>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buckets, hiddenAssetGroups, t, hiddenInactiveAssets, selectedItemId, hiddenAssets, dispatch, toggleVisibility, groupBy]);

  const getBucketResults = useCallback((bucket: string): AssetBasic[] => buckets[bucket] ?? [], [buckets]);

  const theme = useTheme();

  const bucketList = useMemo(
    () => sortedBuckets.map<(BucketHeader | BucketResult)[]>(bucket => {
      const bucketResults = getBucketResults(bucket);
      if (!bucketResults.length) return [];
      const visibleItems = bucketResults.map<BucketResult>(result => ({
        type: 'assetResult',
        result,
        isHidden: hiddenAssetGroups.includes(bucket) || !!hiddenAssets.find(ha => ha.id === result.id),
        isSelected: !!selectedItemId && selectedItemId === result.id,
        height: 57
      })).filter(({ isHidden }) => !isHidden);

      if (visibleItems.length) visibleItems[visibleItems.length - 1].height += theme.spacingNumber(3);

      return [
        {
          type: 'header',
          bucket,
          visibilityIcon: displayVisibilityIcon(bucket),
          height: theme.spacingNumber(visibleItems.length ? 4 : 7)
        },
        ...visibleItems
      ];
    }).reduce((acc, curr) => acc.concat(...curr), []),
    [sortedBuckets, displayVisibilityIcon, getBucketResults, hiddenAssets, selectedItemId, theme, groupBy, hiddenAssetGroups]
  );

  const generateListElement = useCallback(({ index, key, style }: ListRowProps): JSX.Element | null => {
    const bucketElement = bucketList[index];
    if (!bucketElement) { return null; }

    if (bucketElement.type === 'header') {
      return (
        <div key={key} style={style} className={classes.groupHeaderRow}>
          <Typography className={classes.groupHeaderTitle}>{groupBy === 'status' ? t2(bucketElement.bucket) : bucketElement.bucket}</Typography>
          {activeQuery.name === 'Assets' && bucketElement.visibilityIcon}
        </div>
      );
    }

    return <div key={key} style={style} className={classes.assetRow}><QueryResult selectAsset={selectAsset} {...bucketElement} /></div>;
  }, [activeQuery.name, bucketList, classes.groupHeaderRow, classes.groupHeaderTitle, classes.assetRow, groupBy, selectAsset, t2]);

  const getHeight = useCallback((item: { index: number }) => (bucketList[item.index]?.height), [bucketList]);

  const mediaQuery = {
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  useEffect(() => {
    listRef.current?.recomputeRowHeights();
  }, [mediaQuery.md, mediaQuery.lg, mediaQuery.xl]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          // @ts-ignore
          ref={listRef}
          rowCount={bucketList.length}
          overscanRowCount={10}
          height={height}
          rowHeight={getHeight}
          rowRenderer={generateListElement}
          width={width}
        />
      )}
    </AutoSizer>
  );
};

export default GroupedQueryResults;
